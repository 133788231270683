import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import * as Layout2Col from "../components/layout/layout2col/layout2col"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Seo from "../components/seo/seo"

import ResourceCategories from "../components/resource/resourceCategories/resourceCategories"
import ListItem from "../components/shared/listItem/listItem"
import Pagination from "../components/shared/pagination/pagination"

const ResourceCategory = ({ data, pageContext }) => {
  const page = data.sanityResourceCategory
  const seo = data.sanityResourceCategory.seo
  const resources = data.allSanityResource
  const { currentPage, totalPages, pagePath, slug } = pageContext
  const steps = [
    {
      link: `resources`,
      title: "Resources",
    },
    {
      link: `resources/${slug}`,
      title: page.title,
    },
  ]

  return (
    <Layout>
      <Seo isBlogPost={false} title={page.title} path={pagePath} breadcrumbs={steps} {...seo} />
      <Breadcrumbs steps={steps} />
      <HeroSimple blocks={page.hero[0].children} subtitle={page.subtitle} {...seo} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          <div className="grid">
            {resources.nodes.map(resource => (
              <ListItem {...resource.tabs.content} base="resources" />
            ))}
          </div>
          {totalPages > 1 && (
            <Pagination base={`resources/${slug}`} currentPage={currentPage} totalPages={totalPages} />
          )}
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <ResourceCategories />
        </Layout2Col.Sidebar>
      </Layout2Col.Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    sanityResourceCategory(slug: { current: { eq: $slug } }) {
      title
      hero: _rawHero
      subtitle
      seo {
        canonical
        description
        metaTitle
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
        removeSitemap
      }
    }
    allSanityResource(
      sort: { fields: tabs___content___publishDate, order: DESC }
      filter: { tabs: { content: { category: { slug: { current: { eq: $slug } } } } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            subtitle
            excerpt
            category {
              title
              slug {
                current
              }
            }
            slug {
              current
            }
            featuredImage {
              asset {
                fluid(maxWidth: 250) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ResourceCategory
